// @ts-nocheck
import React, { useEffect, useState, Suspense, lazy } from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom"
import Layout from "./components/Layout";
import { connect } from "react-redux";
import { fetchData } from './redux-sharedframework/actions/fetch-data-actions'
import { fetchTokenSuccess } from './redux-sharedframework/actions/token-action'
import FetchData from "./components/FetchData";
import Auth from "./auth/Auth";
import { app } from "@microsoft/teams-js";
import { useTeams } from "@microsoft/teamsfx-react";
import { setEntityId, setNavigatedFromActivity, setIsMobile } from './redux-sharedframework/actions/enitity-actions';
import NewViewIdea from "./components/my-space/view-idea";
import 'bootstrap/dist/css/bootstrap.min.css';
import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./auth/SSO/auth-config";
import jwtDecode from "jwt-decode";
import { PageLoader } from "./common/PageLoader";
import { getTeamstoken } from "./api/http-interceptor-service";
import { appInsights } from "./components/appInsight/AppInsight";
import { RemoveTab } from './tab-configuration/RemoveTab';
import Nudges from './components/Nudges/Nudges';
import NudgeOptInOptOut from './components/my-space/NudgeOptInOptOut';
import NudgeOptInOptOutMobile from './components/mobile/nudge-preferences/NudgeOptInOptOutMobile';
import SubmitIdeaPopupPage from './components/mobile/trending-ideas/SubmitIdeaPopupPage'
import UnautherisedUser from './components/unautherised-user/unautherised-user';
import NotFound from "./components/not-found/not-found";
import Resets from './components/Resets/Resets2';

const AdminPanel = lazy(() => import('./components/admin-panel/adminPanel'));
const AnalyticsDashboard = lazy(() => import('./components/analytics-dashboard/AnalyticsDashboard'));
const AwatingMentorApprovalIdea = lazy(() => import('./components/PIB/middle-feed/AwatingMentorApprovalIdea'));
const CollaborationIdea = lazy(() => import('./components/PIB/middle-feed/CollaborationIdea'));
const CreateChallenge = lazy(() => import('./components/admin-panel/manage-contest/challenge/CreateChallenge'));
const CreateContest = lazy(() => import('./components/admin-panel/manage-contest/contest/CreateContest'));
const DynamicFormRender = lazy(() => import('./components/dynamic-form/DynamicFormRender'));
const DynamicIdeaDetailsView = lazy(() => import('./components/dynamic-idea-details-view/DynamicIdeaDetailsView'));
const EditPrivateIdea = lazy(() => import('./components/my-space/EditPrivateIdea'));
const ExploreIdeas = lazy(() => import('./components/explore-category/explore-ideas'));
const InnovationDashboard = lazy(() => import('./components/PIB/InnovationDashboard'));
const ManageContestLeftPanel = lazy(() => import('./components/admin-panel/manage-contest/ManageContestLeftPanel'));
const MentorIdea = lazy(() => import('./components/explore-idea-mentor/MentorIdea'));
const MyIdea = lazy(() => import('./components/explore-category/my-idea'));
const PatentIdeas = lazy(() => import('./components/patent-category/PatentIdeas'));
const QuickLink = lazy(() => import('./components/contest-homepage/quick-links/QuickLink'));
const QuickLinkGTIC = lazy(() => import('./components/contest-homepage-gtic/quick-links/QuickLink'));
const SerialInnovatorsDetails = lazy(() => import('./components/serial-innovator/SerialInnovatorDetails'));
const ShowSerialInnovators = lazy(() => import('./components/serial-innovator/show-serial-innovators'));
const SubmitIdeaForm = lazy(() => import('./components/submit-idea/SubmitIdeaForm'));
const SubmitPatentIdea = lazy(() => import('./components/patent-category/add-edit-view-patent/SubmitPatentIdea'));
const ViewActiveContests = lazy(() => import('./components/my-space/view-active-contests/ViewActiveContests'));
const ViewContestHomePage = lazy(()=> import('./components/contest-homepage-view/view-contest-homepage/ViewContestHomePage'));
const ViewContestHomePageGTIC = lazy(()=> import('./components/contest-homepage-gtic/view-contest-homepage-gtic/ViewContestHomePage'));
const ViewContestHomePagev360 = lazy(()=> import('./components/contest-homepage/view-contest-homepage-v360/ViewContestHomePage'));
const ViewDynamicIdeaUpdated = lazy(() => import('./components/v360-ideadeatils-view/ViewDynamicIdeaUpdated'));
const ViewIdea = lazy(() => import('./components/view-idea/ViewIdea'));
const ViewPrivateIdea = lazy(() => import('./components/my-space/ViewPrivateIdea'));
const VotingPage = lazy(() => import('./components/voting-dynamic-idea/VotingPage'));
const V360IdeaSubmissionFormRenderUpdated = lazy(() => import('./components/v360-ideasubmission/V360IdeaSubmissionFormRenderUpdated'));
const V360MarketWinner = lazy(() => import('./components/v360-market-winner/V360MarketWinner'));
const V360WinnersDashboard = lazy(() => import('./components/v360-winners-dashboard/V360WinnersDashboard'));
const MentorIdeaMobile = lazy(() => import('./components/mobile/mentor-idea-mobile/MentorIdeaMobile'));
const MentorPatentMobile = lazy(() => import('./components/mobile/mentor-idea-mobile/MentorPatentMobile'));
const SubmitPatentIdeaMobile = lazy(() => import('./components/mobile/patentability-check/patent-details-page/SubmitPatentIdeaMobile'));

// import ComingSoon from "./ComingSoon/ComingSoon";
// import Resets from './components/Resets/Resets2';
// const MentorIdeaMobile = lazy(() => import('./components/mobile/mentor-idea-mobile/MentorIdeaMobile'));
// const MentorPatentMobile = lazy(() => import('./components/mobile/mentor-idea-mobile/MentorPatentMobile'));

// const DynamicFormRenderIOTY = lazy(() => import('./components/dynamic-form-IOTY/DynamicFormRenderIOTY'));
// const EvaluateIdeas = lazy(() => import('./components/evaluate-ideas/EvaluateIdeas'));
// const PDFIdeasDownload = lazy(() => import('./components/explore-category/PdfIdeasDownload'));
// import SelectIdeaToViewInLICTab from './tab-configuration/SelectIdeaToViewInLICTab';
// import ViewIdeaInTab from './tab-configuration/ViewIdeaInTab';
// import ViewPrivateIdeaInTab from './tab-configuration/ViewPrivateIdeaInTab';
// import Configuration from './components/configuration';
// import RecruitingDetails from './components/recruiting-details/recruiting-details';
// import AddQuestions from './components/recruiting-details/questions/add-questions';

interface AppProps {
    fetchData(data): any;
    setEntityId(id): any;
    data: string;
    entityId: string;
    contextSubEntityId: boolean;
    setNavigatedFromActivity(navigatedFromActivity): any;
    setTeamsTokenData(data): any;
    setIsMobile(isMobile): any;
}

//STEP 1:
//create components using React.lazy
const DefaultTheme = React.lazy(() => import('./theme/default/defaultTheme'));
const DarkTheme = React.lazy(() => import('./theme/dark/darkTheme'));
const ContrastTheme = React.lazy(() => import('./theme/contrast/contrastTheme'));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
const ThemeSelector = ({ children }) => {

    // const [{ inTeams, theme, context }] = useTeams("default", overrideThemeHandler);
    const [curTheme, setCurTheme] = useState("default");
    const [{ themeString }] = useTeams();

    // useEffect(() => {
    //     app.initialize();
    //     app.getContext().then((context: app.Context) => {
    //         console.log('theme', context.theme);
    //         console.log('theme useTeams', themeString);
    //         if(themeString) {
    //             setCurTheme(themeString);
    //         } else {    
    //             setCurTheme('default');
    //         } 
    //     });

    //     app.registerOnThemeChangeHandler(function (theme) {
    //         console.log('theme curTheme',theme);
    //         setCurTheme(theme);
    //     });

    // }, [curTheme]);

    useEffect(() => {
        console.log('theme useTeams', themeString);
        console.log('theme curTheme', curTheme);
        // if(themeString) {
        //     setCurTheme(themeString);
        // } else {    
        //     setCurTheme('default');
        // } 
    }, [themeString]);


    return (
        <>

            <Suspense fallback={<></>}>
                {(curTheme === 'default') && <DefaultTheme />}
                {(curTheme === 'dark') && <DarkTheme />}
                {(curTheme === 'contrast') && <ContrastTheme />}
            </Suspense>
            {children}

        </>
    )
}

// const overrideThemeHandler = (theme) => {
// };


const App = ({ setEntityId, setNavigatedFromActivity, data, setTeamsTokenData, setIsMobile }: AppProps) => {

    const [{ inTeams}] = useTeams();
    const [isAuthenticated, setIsAuthenticated] = useState<Boolean>(false);
    const msalInstance = new PublicClientApplication(msalConfig);
    const [showSpinner, setSpinnerData] = useState(true);
    const [comingSoon, setComingSoon] = useState(false);

    useEffect(() => {
        function updateSize() {
            if (navigator.userAgent.match(/iPhone/i) !== null) {
                setIsMobile(true);
            } else if (
                navigator.userAgent.match(/Android/i) !== null &&
                (parseInt(window.innerWidth.toString()) < 768 ||
                    parseInt(window.innerHeight.toString()) < 768)
            ) {
                setIsMobile(true);
            }
            else {
                setIsMobile(false);
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    useEffect(() => {
        if (inTeams !== undefined && inTeams === true) {
            //console.log("start microsoft Teams initialize");
            app.initialize();
            //console.log("end microsoft Teams initialize");
            
            //console.log("start getTeamstoken");
            const telemetryInitializer = function () {
                appInsights.addTelemetryInitializer(function (envelope) {
                    envelope.ext.user.id = envelope.ext.user.authId; // updates anonymous id generated by sdk with userid
                });
            };


            getTeamstoken().then((response) => {
                if (response) {
                    // console.log("microsoft Teams auth success");
                    const decoded: { [key: string]: any; } = jwtDecode(response) as { [key: string]: any; };

                    let loginEnterpriseId: any;
                    if (decoded?.upn) {
                         loginEnterpriseId = (decoded!.upn.split('@')[0]).toLowerCase();
                    }
                    else {
                        loginEnterpriseId = (decoded!.email.split('@')[0]).toLowerCase();
                    }
                    setEntityId(loginEnterpriseId);
                    appInsights.setAuthenticatedUserContext(loginEnterpriseId);
                    if (appInsights.queue !== undefined) {
                        appInsights.queue.push(telemetryInitializer);
                    } else {
                        telemetryInitializer();
                    }
                    setIsAuthenticated(true);
                    setTeamsTokenData(response);
                }
            }).catch(err => console.log("login error:", err));

            app.getContext().then((context: app.Context) => {
                if (context?.page?.subPageId != null && context?.page?.subPageId != '' && context?.page?.subPageId.length > 0) {
                    console.log('>>>set true');
                    setNavigatedFromActivity(true);
                } else {
                    console.log('>>>set false');
                    setNavigatedFromActivity(false);
                }
            });
            if (process.env.REACT_APP_COMINGSOON === "TRUE") {
                setComingSoon(true);
            }
            setSpinnerData(false);
        }
        else if (inTeams !== undefined && inTeams === false) {
            if (process.env.REACT_APP_SSO_WEB_ENABLE === "TRUE") {
                login();
            }
            else {
                

                //setEntityId("p.a.kalra");
                //setEntityId("sivapreethi.selvam");
                //setEntityId("karishma.b.gupta");
                //setEntityId("nikhil.h.arora");
                //setEntityId("saurabh.d.goyal");
                //setEntityId("neha.sohail");
                //setEntityId("deepthi.rajasekar");
                //setEntityId("anurag.c.garg");
                //setEntityId("s.tushar.chaudhari");
                //setEntityId("mandavi.patel");
                // setEntityId("anusha.a.cs");
                //setEntityId("avnish.mukherjee");
                //setEntityId("rahul.a.s.kumar");
                //setEntityId("sivapreethi.selvam");
                //setEntityId("avnish.mukherjee");
                // setEntityId("sindhu.devaraj");
                //setEntityId("varsha.a.karthikeyan");

                getTeamstoken().then((response) => {
                    setTeamsTokenData(response);
                });
                setIsAuthenticated(true);
            }
            setSpinnerData(false);
        }
    }, [inTeams]);


    const handleResponse = (resp) => {
        // console.log("resp=>", resp);
        let accountId = "";
        if (resp !== null) {
            // console.log("reached");
            accountId = resp.account.username;
        } else {
            // need to call getAccount here?
            // console.log("reached here");
            const currentAccounts = msalInstance.getAllAccounts();
            // console.log("currentAccounts", currentAccounts);
            if (!currentAccounts || currentAccounts.length < 1) {
                return;
            } else if (currentAccounts.length > 1) {
                // Add choose account code here
                accountId = currentAccounts[0].username;
                // console.log("active acount id length", accountId);
                setEntityId(accountId.split('@')[0]);
                setIsAuthenticated(true);
            } else if (currentAccounts.length === 1) {
                accountId = currentAccounts[0].username;
                // console.log("active acount id", accountId);
                setEntityId(accountId.split('@')[0]);
                setIsAuthenticated(true);
            }
        }
        return accountId;
    };

    const login = async () => {
        try {

            let loginresp = null;
            let accountID = null;
            const checkAccountIDAvailable = handleResponse(null);
            // console.log("checkAccountIDAvailable", checkAccountIDAvailable);
            if (checkAccountIDAvailable) {
                setEntityId(checkAccountIDAvailable.split('@')[0]);
                setIsAuthenticated(true);
            }
            else {
                await msalInstance.handleRedirectPromise().then(handleResponse);
                await msalInstance.loginRedirect(loginRequest);
            }
        }
        catch (err) {
            setIsAuthenticated(false);
        }
    };

    return (
        <>
            {showSpinner ? <PageLoader hidden={false} /> : ""}
            <ThemeSelector>
                {isAuthenticated &&
                    <>
                        <Auth />
                        <Layout>
                            <Suspense fallback={<PageLoader hidden={false} />}>
                                <Switch>
                                    <Route exact path="/" component={NewViewIdea} />
                                    <Route path="/adaptivecard" component={Nudges} />
                                    <Route exact path="/admin-panel" component={AdminPanel} />
                                    <Route exact path="/analytics-dashboard" component={AnalyticsDashboard} />
                                    <Route exact path="/dynamic-form/:contestIdForDynamicContest" component={DynamicFormRender} />
                                    <Route exact path="/awaiting-mentor-approval" component={AwatingMentorApprovalIdea} />
                                    <Route exact path="/collaboration-idea" component={CollaborationIdea} />
                                    <Route exact path="/create-challenge" component={CreateChallenge} />
                                    <Route exact path="/create-contest" component={CreateContest} />
                                    <Route exact path="/dynamic-idea-details-view" component={DynamicIdeaDetailsView} />
                                    <Route exact path="/editPrivateIdea" component={EditPrivateIdea} />
                                    <Route exact path="/exploreideas" component={ExploreIdeas} />
                                    <Route exact path="/fetch-data/:startDateIndex?" component={FetchData} />
                                    <Route exact path="/innovation-dashboard" component={InnovationDashboard} />
                                    <Route exact path="/landing" component={NewViewIdea} />
                                    <Route exact path="/manage-contest" component={ManageContestLeftPanel} />
                                    <Route exact path="/myideaspace" component={({ location }) => comingSoon ? <ComingSoon /> : <NewViewIdea location={location} />} />
                                    <Route exact path="/my-submit-idea" component={MyIdea} />
                                    <Route exact path="/mentorideas" component={MentorIdea} />
                                    <Route exact path="/nudges" component={() => comingSoon ? <ComingSoon /> : <Nudges />} />
                                    <Route exact path="/nudgepreference" component={NudgeOptInOptOut} />
                                    <Route exact path="/nudgepreferencemobile" component={NudgeOptInOptOutMobile} />
                                    <Route exact path="/patentideas" component={PatentIdeas} />
                                    <Route exact path="/quick-link" component={QuickLink} />
                                    <Route exact path="/quick-link/:contestIdForContestHomePage" component={QuickLink} />
                                    <Route exact path="/quick-link-gtic" component={QuickLinkGTIC} />
                                    <Route exact path="/quick-link-gtic/:contestIdForContestHomePage" component={QuickLinkGTIC} />
                                    <Route exact path="/remove-tab" component={RemoveTab} />                       
                                    <Route exact path="/submit-idea" component={SubmitIdeaForm} />
                                    <Route exact path="/submit-idea/:contestId" component={SubmitIdeaForm} />
                                    <Route exact path="/submit-idea-popup-page" component={SubmitIdeaPopupPage} />
                                    <Route exact path="/submitPatentIdea" component={SubmitPatentIdea} />
                                    <Route exact path="/unautherised-user" component={UnautherisedUser} />
                                    <Route exact path="/viewprivateidea" component={ViewPrivateIdea} />
                                    <Route exact path="/view-all-active-contests" component={ViewActiveContests} />
                                    <Route exact path="/view-idea" component={ViewIdea} />
                                    <Route exact path="/view-idea/:viewIdeaId" component={ViewIdea} />
                                    <Route exact path="/view-contest-homepage/:contestIdForContestHomePage" component={ViewContestHomePage} />
                                    <Route exact path="/view-contest-homepage-gtic/:contestIdForContestHomePage" component={ViewContestHomePageGTIC} />
                                    <Route exact path="/view-contest-homepage-ioty/:contestIdForContestHomePage" component={ViewContestHomePageGTIC} />
                                    <Route exact path="/view-contest-homepage-solutioning/:contestIdForContestHomePage" component={ViewContestHomePageGTIC} />
                                    <Route exact path="/view-contest-homepage-sustainability/:contestIdForContestHomePage" component={ViewContestHomePageGTIC} />
                                    <Route exact path="/view-contest-homepage-v360" component={ViewContestHomePagev360} />
                                    <Route exact path="/view-contest-homepage-v360/:contestIdForContestHomePage" component={ViewContestHomePagev360} />
                                    <Route exact path="/view-dynamic-idea" component={ViewDynamicIdeaUpdated} />
                                    <Route exact path="/view-serial-innovators" component={ShowSerialInnovators} />
                                    <Route exact path="/vote-ideas" component={VotingPage} />
                                    <Route exact path="/view-serial-innovators/:enterpriseId" component={SerialInnovatorsDetails} />
                                    <Route exact path="/v360-idea-submission-form/:contestIdForV360" component={V360IdeaSubmissionFormRenderUpdated} />
                                    <Route exact path ="/v360-winners" component= {V360WinnersDashboard}/>
                                    <Route exact path ="/v360-market-winner" component= {V360MarketWinner}/>
                                    <Route exact path="/getstarted" component={() => comingSoon ? <ComingSoon /> : <Resets />} />
                                    <Route exact path="/submitPatentIdeaMobile" component={SubmitPatentIdeaMobile} />
                                    <Route exact path="/MentorIdeaMobile" component={MentorIdeaMobile} />
                                    <Route exact path="/MentorPatentMobile" component={MentorPatentMobile} />
                                    <Route path="*" component={NotFound}/>
                                </Switch>

                                {/*
                                    
                                        <Route exact path="/submitPatentIdeaMobile" component={SubmitPatentIdeaMobile} /> -- Mobile
                                    <Route exact path="/tab-configuration" component={SelectIdeaToViewInLICTab} /> -- Not Used
                                    <Route exact path="/view-contest-idea-tab/:viewIdeaId" component={ViewIdeaInTab} /> -- Not Used
                                    <Route exact path="/view-private-idea-tab/:viewIdeaId" component={ViewPrivateIdeaInTab} /> -- Not Used
                                    <Route exact path="/view-contest-homepage" component={ViewContestHomePage} /> -- not used anywhere used only with contestid route
                                    <Route exact path="/dynamic-form-ioty/:contestIdForDynamicContest" component={DynamicFormRenderIOTY} /> -- only routed from app.tsx nowhere else used
                                    <Route exact path ="/pdf-ideas-download" component={PDFIdeasDownload}/> -- to confirm if this is required
                                    <Route exact path='/configure' component={Configuration}></Route> -- to confirm if this is required
                                    <Route exact path='/questions' component={AddQuestions}></Route> -- to confirm if this is required
                                    <Route exact path='/questions/:token/:contestId/:scaleRange' component={AddQuestions}></Route> -- to confirm if this is required
                                    <Route exact path="/evaluate-ideas" component={EvaluateIdeas} /> //Not to consider as per Rahul
                                    <Route exact path="/dynamic-evaluate-ideas" component={EvaluateIdeasDynamic} /> //Not to consider as per Rahul
                                    <Route exact path='/details/:scaleRange/:currentSelectedContest/:currentSelectedContestId/:juryId/:type/:isAutosaveFeatureEnabled' component={RecruitingDetails}></Route>   //Not to consider as per Rahul
*/
                                }

                            </Suspense>
                        </Layout>
                    </>
               }
            </ThemeSelector>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        data: state.fetchDataReducer.data,
        error: state.fetchDataReducer.error
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchData: (data) => { dispatch(fetchData(data)) },
        setEntityId: (id: string) => dispatch(setEntityId(id)),
        setNavigatedFromActivity: (navigatedFromActivity: boolean) => dispatch(setNavigatedFromActivity(navigatedFromActivity)),
        setTeamsTokenData: (data) => { dispatch(fetchTokenSuccess(data)) },
        setIsMobile: (isMobile: boolean) => dispatch(setIsMobile(isMobile)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
